import {_get, _put, _post, _delete} from "@api/services/httpService";

const URL = 'news';

export const getNewsById = (id) => _get(URL + '/' + id);

export const putNews = (id, data) => _put(URL + '/' + id, data);

export const deleteNews = (id) => _delete(URL + '/' + id);

export const getNews = (page, itemPerPage, search = '') => {
  let url = '/news?itemsPerPage=' + itemPerPage + '&page=' + page;

  if (search !== '') {
    url += '&search=' + search;
  }

  return _get(url)
};

export const postNews = (news) => _post(URL, news);
