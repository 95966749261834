<template>
  <div>
  </div>
</template>
<script>
import {deleteNews, getNews} from "@api/services/news/news.api";

const moment = require('moment');

export default {
  data: () => ({
    visible: false,
    passed: false,
    updatedTab: false,
    isBusy: true,
    commingSoon: true,
    canceled: false,
    items: [],
    itemsPerPage: 30,
    currentPage: 1,
    totalRows: 1,
    hide: true,
    show: true,
    isCanceled: false,
    operator: 'after',
    date: moment().format('YYYY-MM-DD'),
    bookingsList: [],
    idClub: null,
    filter: ''
  }),
  created() {
    window.open("https://admin.doinsport.club/actuality", '_blank');
    this.$router.push({name: "clubs"})
  },
  computed: {
    tableFields() {
      return [
        {key: "title", label: this.$t("admin.news.list.title"), sortable: true},
        {key: "visibleFromDate", label: this.$t("admin.news.list.publication-date"), sortable: true},
        {key: "visibleToDate", label: this.$t("admin.news.list.publication-end"), sortable: true},
        {key: "enabled", label: this.$t("general.actions.status"), sortable: true},
        {key: "actions", label:  this.$t("general.actions.actions")},
      ];
    }
  },
  mounted() {
  },
  methods: {
    onDelete(scope) {
      deleteNews(scope.id)
        .then(() => {
          this.loadNews();
        })
      ;
    },
    onRowClicked(scope) {
      this.$router.push({name: 'update_form', params: {id: scope.id}});
    },
    loadNews() {
      this.isBusy = true;
      this.items = [];

      getNews(this.currentPage, this.itemsPerPage, this.filter)
        .then(resp => {
          if (resp.data) {
            resp.data['hydra:member']
              .forEach(item => {
                this.items.push({
                  iri: item['@id'],
                  title: item.title,
                  visibleFromDate: item.visibleFromDate ? this.$moment(item.visibleFromDate).format('DD-MM-YYYY') : this.$t('general.actions.undefined'),
                  visibleToDate: item.visibleToDate ? this.$moment(item.visibleToDate).format('DD-MM-YYYY') : this.$t('general.actions.undefined'),
                  enabled: this.formatStatus(item),
                  id: item.id,
                  actions: ['edit', 'delete']
                });
              });
            this.isBusy = false;
            this.totalRows = resp.data['hydra:totalItems'];
          }
        });
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadNews();
    },
    formatStatus(item) {
      if (item.enabled === false) {
        return this.$t('admin.news.list.status.draft');
      } else {
        const visibleFromDate = this.$moment(item.visibleFromDate);
        const visibleToDate = item.visibleToDate ? this.$moment(item.visibleToDate) : null;
        const toDay = this.$moment();

        if (null !== visibleToDate) {
          if (visibleToDate.isAfter(toDay)) {
            if (visibleFromDate.isAfter(toDay)) {
              return this.$t('admin.news.list.status.programmed');
            }

            if (visibleFromDate.isBefore(toDay)) {
              return this.$t('admin.news.list.status.published');
            }
          } else {
            return this.$t('admin.news.list.status.expired');
          }
        } else {
          return this.$t('admin.news.list.status.programmed');
        }
      }
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadNews();
    },
    search() {
      this.loadNews();
    },
    addClub() {
      this.$router.push({name: 'news_form'});
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 400);
    },
  }
};
</script>
<style scoped>
.all-bookings-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 263px;
  padding: 1rem;
}

.all-bookings .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>
